import { Pipe, PipeTransform } from '@angular/core';
import { ApplicantState } from '../../../__generated__/types';

@Pipe({
  name: 'applicanState',
  standalone: true,
})
export class ApplicanStatePipe implements PipeTransform {
  transform(value: ApplicantState | ApplicantState[]): string {
    if (Array.isArray(value)) {
      return this.getName(value[0]);
    } else {
      return this.getName(value);
    }
  }

  private getName(state: ApplicantState): string {
    switch (state) {
      case ApplicantState.New:
        return 'Neue Bewerber';
      case ApplicantState.Screened:
        return 'Erstgespräch';
      case ApplicantState.Interviewed:
        return 'Fragebogen';
      case ApplicantState.Invited:
        return 'Eingeladen';
      case ApplicantState.Hired:
        return 'Angenommen';
      case ApplicantState.Declined:
        return 'Abgelehnt';
      default:
        return 'Unbekannt';
    }
  }
}
